import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

import "reset-css";
import "../index.css";

import bgLarge from "../images/bg-large.jpg";
import bgSmall from "../images/bg-small.jpg";
import logo from "../images/logo.svg";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
`;

const Main = styled.main`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-image: url("${bgSmall}");
  background-size: cover;

  @media (min-width: 700px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-position: center;
    background-image: url("${bgLarge}");
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  text-align: center;
  max-width: 460px;
  padding: 20px;
  margin: auto;

  @media (min-width: 700px) {
    display: inline-block;
    max-width: 400px;
    min-height: auto;
    margin: 50px 120px;
    padding: 0;
  }
`;

const Logo = styled.img`
  width: 240px;
  opacity: 0.9;
  margin-left: 17px;
`;

const Title = styled.h1`
  font-family: "TudorVictors", georgia, serif;
  color: #f8f7f5;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  text-transform: uppercase;
  font-size: 30px;
  padding-top: 50px;

  @media (min-width: 700px) {
    padding-top: 80px;
  }
`;

const Text = styled.p`
  font-size: 20px;
`;

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Battle Warrior Coffee</title>
        <meta
          name="p:domain_verify"
          content="39d099aff79720370fd7cd23f026b3e8"
        />
      </Helmet>
      <Main>
        <GlobalStyle />

        <Container>
          <Logo src={logo} />

          <div>
            <Title>We'll be back warriors</Title>

            <Text>Until then keep the battle strong</Text>
          </div>
        </Container>
      </Main>
    </>
  );
};

export default IndexPage;
